import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../../layouts/Layout';
import JobTable from '../../components/JobTable/JobTable';

import menuItems from '../../constants/menuItems';

const SearchPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			location={props.location}
			menuItems={menuItems}
			title={'Coach USA Search Opportunities'}
			desc={'Coach USA Search Opportunities'}
		>
			<JobTable data={data} />
		</Layout>
	);
};

export default SearchPage;
