import * as React from 'react';
// @ts-ignore
import { JobList } from 'career-page-components';
import './JobTable.css';

interface JobTableProps {
	data: any;
}
const JobTable: React.FC<JobTableProps> = (props: JobTableProps) => {
	const jobListOptions = {
		jobListClassNames: 'job-list',
		searchBarWrapperClassNames: 'search-bar-wrapper',
		searchBarClassNames: 'search-bar',
		jobResultsTitleWrapperClassNames: 'results-wrapper',
		jobResultsTitleClassNames: 'search-results',
		jobListWrapperClassNames: 'job-list-wrapper',
		buttonText: 'LEARN MORE'
	};
	const applyItems = [
		{
			text: 'ReqID:',
			value: 'id',
			hideLabel: false
		}
	];
	return (
		<section className={'JobListSection'}>
			<div className={'bg-primary pt-8'}>
				<div className={'container mx-auto px-3'}>
					<h1
						className={
							'mt-5 text-center text-[40px] font-bold text-white lg:text-left'
						}
					>
						Job Listings
					</h1>
				</div>
			</div>
			<div className="mx-auto">
				<JobList
					data={props.data}
					options={jobListOptions}
					searchTargets={['title', 'city', 'id', 'category', 'state']}
					apiKey={'AIzaSyAdZiU_HnkEoqTNEIctfBGTagt0qbCyf_0'}
					applyNowValues={applyItems}
				/>
			</div>
		</section>
	);
};

export default JobTable;
